@import "variables";

.e-treeview {
  display: flex;
  height: calc(100% - 141px);
  overflow: auto;
  flex-direction: column;
  &.e-drag-item {
    height: auto;
  }
  .tree__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .e-ul {
    padding: 0 !important;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
  .e-list-icon {
    &.TIMELINE {
      background-image: url("/assets/icons/board.svg");
      margin: 0;
    }
    &.WIDGET {
      background-image: url("/assets/icons/dashboard.svg");
      margin: 0;
    }
  }

  .e-list-item {
    .treeName {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .active {
      color: var(--primary);
    }
    &.e-disable > .e-text-content,
    &.e-disable > .e-fullrow {
      opacity: 1;
      filter: alpha(opacity=100);
      .e-list-text {
        cursor: default;
      }
    }
    &.e-level-1 {
      .e-fullrow {
        height: 40px;
      }
      padding-left: 1rem;
      &.e-hover > .e-text-content .e-list-text {
        color: var(--dark) !important;
      }
      &.e-active > .e-text-content .e-list-text {
        color: var(--primary) !important;
      }
    }
    &.e-level-2 {
      &.e-hover > .e-text-content .e-list-text,
      &.e-active > .e-text-content .e-list-text {
        color: var(--primary) !important;
      }
    }
  }
  .e-text-content {
    display: flex;
    align-items: center;
    padding: 0;
    .e-icon-expandable,
    .e-icon-collapsible {
      margin-left: 0;
    }
    .e-list-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      font-size: $font-size-x-lg;
      padding: 0 10px;
      overflow: hidden;
      .node__text {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      a {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .e-input-group {
        width: 100% !important;
      }
      itq-nav-button {
        itq-button {
          button {
            padding: 0 !important;
            &:hover {
              background: transparent !important;
            }
          }
        }
      }
    }
  }
  .e-list-parent.e-ul {
    overflow: unset;
    .e-list-parent.e-ul {
      padding-left: $margin-lg !important;
      height: 100%;
    }
  }
}
