$margin-xxx-sm: 0.15rem;
$margin-xx-sm: 0.25rem;
$margin-x-sm: 0.5rem;
$margin-sm: 0.75rem;
$margin-md: 1rem;
$margin-lg: 1.75rem;
$margin-x-lg: 2rem;
$margin-xx-lg: 2.25rem;
$margin-xxx-lg: 3rem;

:root {
  --margin-lg: 1.75rem;
  --margin-x-sm: 0.5rem;
  --margin-xx-sm: 0.25rem;
  --margin-sm: 0.75rem;
  --margin-md: 1rem;
}
