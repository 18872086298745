html,
body {
  font-family: $font-family-roboto;
  height: 100%;
  font-weight: lighter;
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  font-size: $font-size-x-lg;
  overflow: hidden;
}

a {
  color: var(--dark);
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited {
    color: initial;
  }
}
*,
:after,
:before {
  box-sizing: border-box;
}
:focus {
  outline: none;
}

label {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: bold;
}

input::selection {
  background-color: var(--primary) !important;
  color: var(--light) !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

dt {
  font-weight: bold;
}

dd {
  font-weight: lighter;
  padding: 0;
  margin: 0;
  margin-bottom: $margin-sm;
}

textarea {
  font-family: $font-family-rubik;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
input:disabled {
  background: inherit;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
