.itq-auth {
  background-repeat: no-repeat;
  background-size: 850px;
  background-position: 50px -200px;
  background-attachment: fixed;
  height: auto;
  .itq-auth-container {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    background: var(--dark);
    width: 100%;
    padding: 40px 20px;
    border-radius: 2px;
    .itq-auth-container-header {
      width: 100%;
      h1.brand-name {
        font-family: $font-family-mont;
        font-weight: 500;
        font-size: 2.5rem;
        color: var(--light);
      }
      h2 {
        color: var(--light);
      }
      .brand-logo {
        width: 70px;
      }
      .alert {
        width: auto;
        margin-bottom: 0;
      }
    }
    .itq-auth-content {
      width: 100%;
      .form-group {
        width: auto;
        .form-control {
          line-height: 1rem;
          height: 40px;
          font-size: 1rem;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          line-height: 1rem;
          height: 40px;
          font-size: 1rem;
        }
      }
      .itq-auth-form {
        width: auto;
        &.itq-auth-form-login {
          .custom-control.custom-checkbox {
            label {
              color: var(--light);
              font-size: 1rem;
              &:before {
                margin-top: 2px;
              }
              &:after {
                margin-top: 1px;
              }
            }
          }
        }
      }
      .itq-auth-form-message.alert-info {
        margin: auto;
        width: 75%;
        background-color: #007bff;
        border-color: #ced6de;
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin: auto auto 0;
        color: var(--light);
      }
      a {
        font-size: 1rem;
        text-decoration: underline;
        color: var(--secondary, var(--secondary));
      }
      .btn {
        min-width: 200px;
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 2px;
      }
      input {
        border-radius: 2px;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px var(--light) inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
}
