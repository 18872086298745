@import "../../abstracts/variables";

simple-snack-bar {
  .mat-simple-snackbar-action {
    .mat-button {
      color: var(--primary);
    }
  }
}
.error__message {
  ::ng-deep {
    simple-snack-bar {
      .mat-simple-snackbar-action {
        .mat-button {
          color: var(--danger) !important;
        }
      }
    }
  }
}
