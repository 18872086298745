// Box shadows
$box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
$box-shadow-semibold: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
$box-shadow-bold: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
$box-shadow-boldest: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
$box-shadow-main-container: inset 0px 4px 4px rgba(0, 0, 0, 0.05);

:root {
  --box-shadow-main-container: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
  --box-shadow-container: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --box-shadow-right: 10px 0px 12px -3px rgb(0 0 0 / 10%);
  --box-shadow-left: -10px 0px 12px -3px rgb(0 0 0 / 10%);
}
