@import "../abstracts/mixins";
@import "../abstracts/variables";

p.lead {
  font-size: 14px;
  padding-top: 5px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

h1 {
  margin-bottom: 0px;
  font-weight: var(--font-weight-bold);
  .category {
    display: none;
    font-weight: $font-weight-light;
    padding-right: 5px;
  }
}

.seperator {
  border-top: 1px solid #d8d8d8;
  height: 1px;
  padding-top: 30px;
}

.label-secondary {
  color: var(--secondary) !important;
  text-decoration: underline;
  font-weight: var(--font-weight-semibold);

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: var(--secondary) !important;
  }
}
.p-relative {
  position: relative;
}

.label {
  font-weight: var(--font-weight-semibold);
}

.small-text {
  font-size: var(--font-size-sm);
  font-style: italic;
}

.info-label {
  color: var(--metadata);
  font-size: $font-size-md;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.split-panel--split {
  .grid__wrapper {
    height: calc(100% - 50px);
  }
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.justify-content-right {
  justify-content: right !important;
}

.justify-content-left {
  justify-content: left !important;
}

.text-right {
  text-align: right !important;
}

.m-md {
  margin: var(--margin-md) !important;
}

.mt-md {
  margin-top: $margin-md !important;
}

.mt-lg {
  margin-top: var(--margin-lg) !important;
}

.ml-md {
  margin-left: $margin-md !important;
}

.ml-sm {
  margin-left: var(--margin-sm) !important;
}

.ml-x-sm {
  margin-left: var(--margin-x-sm) !important;
}

.mb-md {
  margin-bottom: $margin-md !important;
}

.mb-x-sm {
  margin-bottom: var(--margin-x-sm) !important;
}

.mt-x-sm {
  margin-top: var(--margin-x-sm) !important;
}

.mb-lg {
  margin-bottom: var(--margin-lg) !important;
}

.mb-sm {
  margin-bottom: var(--margin-sm) !important;
}

.mb-xx-sm {
  margin-bottom: $margin-xx-sm !important;
}

.mr-md {
  margin-right: $margin-md !important;
}

.mr-sm {
  margin-right: $margin-sm !important;
}

.mr-x-sm {
  margin-right: $margin-x-sm !important;
}

.mr-xx-sm {
  margin-right: $margin-xx-sm !important;
}

.pl-x-sm {
  padding-left: var(--margin-x-sm);
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.label-primary {
  color: var(--primary) !important;
}

.label-danger {
  color: var(--danger) !important;
}

.label-warning {
  color: var(--warning) !important;
}

.info-label {
  color: var(--metadata);
}

.d-block {
  display: block;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-center {
  justify-content: center !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.align-center {
  align-items: center;
}

.tooltip__br {
  white-space: pre-line;
}

.w-100 {
  width: 100%;
}
.title {
  font-size: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  font-weight: $font-weight-semibold;
  font-family: $font-family-mont;
  padding-top: 5px;
}

.btn--back {
  display: flex;
  align-items: center;
}

.mat-tooltip {
  display: flex;
  position: relative;
  z-index: 1000000;
  margin-left: 20px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%) !important;
  background-color: var(--primary) !important;
  font-size: 13px !important;
  color: var(--dark) !important;
  padding: $margin-sm !important;
  font-weight: var(--font-weight-semibold);
}

.remove-opacity {
  opacity: 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

svg-icon-sprite {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fill {
  @include fill;
}
mat-datepicker-content {
  .mat-card {
    padding: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  }
  .mat-calendar-body-selected {
    color: var(--light) !important;
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border: 1px dashed var(--primary) !important;
    border-radius: 100%;
  }
  &.mat-datepicker-content {
    border-radius: 0 !important;
  }
  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator {
    color: var(--dark) !important;
  }
  itq-date-range-presets,
  .mat-calendar {
    height: 396px !important;
  }

  itq-date-range-presets {
    button {
      border-radius: 0 !important;
    }
  }
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px var(--color-border) !important;
  }
  .mat-calendar-body-disabled {
    opacity: 0.5 !important;
  }
}

quill-editor {
  background-color: var(--light);
  border-radius: var(--border-radius-x-sm);
  &:focus-within {
    border-color: #90f396;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #1de62a40;
    display: flex;
    flex-direction: column;
    margin: 0.2rem;
  }
  .ql-editor {
    font-size: $font-size-lg;
    font-weight: 300;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  }
  .ql-container,
  .ql-toolbar {
    border: 1px solid var(--color-border) !important;
    &:focus-within {
      border: 0 !important;
    }
  }

  .ql-container {
    border-top: 0 !important;
    width: 100% !important;
    .ql-editing {
      left: 0 !important;
      &::before {
        display: none !important;
      }
      input {
        width: auto !important;
      }
    }
  }
}

.highlight {
  background-color: var(--primary);
  font-weight: bold;
}

.drag-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.quill__container {
  display: flex;
  flex-direction: column;
  span {
    font-weight: var(--font-weight-bold);
  }
}

.required::after {
  content: "*";
}

.editor__container {
  width: 300px;
}

.mat-datepicker-content {
  .mat-datepicker-actions button {
    box-shadow: none !important;
  }
}

.tooltip_container {
  display: flex;
  flex-direction: column;
}

.unread__container {
  position: relative;
  display: flex;
  .unread__count {
    position: absolute;
    right: -19px;
    top: -5px;
    border-radius: 100%;
    background: var(--secondary);
    font-size: 10px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    font-weight: var(--font-weight-semibold);
  }
  .unread__title {
    z-index: 100;
  }
}

.subtitle {
  color: var(--metadata);
  font-size: var(--font-size-lg);
  font-weight: normal;
  line-height: unset;
  font-family: $font-family-rubik;
}
