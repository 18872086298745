@import "./base";
@import "./buttons";
@import "./typography";
@import "./reset";

.navigation__container {
  display: none;
  min-width: 150px;
  flex-direction: column;
  position: absolute;
  padding: 0;
  background: var(--light);
  box-shadow: $box-shadow-bold;
  border-radius: $border-radius-x-sm;
  &::after {
    bottom: 100%;
    right: 1rem;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
}
