@import "variables";

mat-list {
  height: 100%;
  overflow: auto;
  padding: 0 !important;
  .mat-list-item-content {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-sm);
  }
}
