@import "../../abstracts/variables";

.mat-step-header {
  margin-bottom: var(--margin-x-sm);
  .mat-step-icon {
    background-color: var(--dark) !important;
    color: var(--light);
    .mat-step-icon-content {
      fa-icon {
        color: var(--light);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mat-step-icon-selected {
    background-color: var(--primary) !important;
    .mat-step-icon-content {
      fa-icon {
        color: var(--dark);
      }
    }
  }
}

.mat-selections-list {
  .mat-vertical-stepper-header {
    padding: var(--margin-md) var(--margin-sm);

    .mat-step-text-label {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-lg);
      flex-grow: unset;
      border-bottom: 2px solid var(--primary);
      line-height: 1.5;
    }
  }
}

.mat-vertical-content-container {
  margin-left: 24px;
  .mat-vertical-content {
    padding-bottom: 0;
    padding-right: 0;
    padding-left: var(--margin-md);
  }
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: var(--margin-sm) !important;
}

.mat-step-header .mat-step-icon-state-done {
  color: var(--primary) !important;
}

@media only screen and (max-width: $breakpoints-lg-laptop) {
  .mat-vertical-content-container {
    margin-left: 24px !important;
  }
}

.mat-horizontal-stepper-header-container {
  order: 0 !important;
}
