@import "./variables";
@import "./breakpoints";

@mixin header {
  background: var(--dark);
  opacity: 0.4;
}

@mixin container {
  padding: $margin-lg;
  background: var(--color-background);
}

@mixin wrapper {
  padding: $margin-md $margin-lg;
  background: var(--light);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin recursive-selector($root, $limit, $select: "", $padding: $margin-xx-lg) {
  $nester: " .table__row-container";
  @for $i from 1 through $limit {
    $root: $root + $nester;

    #{$root + " " + $select} {
      padding-left: $padding * $i;
    }
  }
}

@mixin fill {
  background: var(--color-background);
  padding: $margin-md;
  border-radius: $border-radius-x-sm;
}

@mixin d-flex-box {
  display: flex;
  width: 100%;
  height: 100%;
}
