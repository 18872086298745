h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-mont;
  margin: 0;
  padding: 0;
  font-weight: 500;
}
h1 {
  font-size: 1.4rem;
}
h2 {
  line-height: 24.5px;
}
p {
  &.lead {
    font-weight: 300;
    font-size: 16px;
  }
}
