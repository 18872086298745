/* Variables */
@import "./abstracts/variables";

/* Theme */
@import "./themes/default";

@import "./base/main";

/* Components */
@import "./components/auth";

/* SyncFusion */
@import "./vendors/sync-fusion/sync-fusion";

// Material UI
@import "./vendors/materials/material";

@tailwind base;
@tailwind components;
@tailwind utilities;
