$breakpoints-sm-mobile: 320px;
$breakpoints-md-mobile: 375px;
$breakpoints-lg-mobile: 425px;
$breakpoints-tablet: 768px;
$breakpoints-laptop: 1024px;
$breakpoints-lg-laptop: 1440px;
$breakpoints-x-lg-laptop: 1750px;
$breakpoints-4k-laptop: 2560px;

:root {
  --breakpoints-sm-mobile: 320px;
  --breakpoints-md-mobile: 375px;
  --breakpoints-lg-mobile: 425px;
  --breakpoints-tablet: 768px;
  --breakpoints-laptop: 1024px;
  --breakpoints-lg-laptop: 1440px;
  --breakpoints-x-lg-laptop: 1750px;
  --breakpoints-4k-laptop: 2560px;
}
