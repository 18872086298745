:root {
  --main-navigation-header: 54px;
  --main-header-height: 128px;
  --table-toolbar-height: 42px;
  --header-height: 93px;
  --header-height-sm: 73px;
  --control-height: 36px;
  --table-header-height: 56px;
  --table-footer-height: 53px;
  --table-row-height: 48px;
  --toolbar-header-height: 36px;
  --board--header-height: 117px;
}
