// Google fonts
$font-path: "../fonts/" !default;

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-Black.eot");
  src: url("#{$font-path}Mont-Black.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-Black.svg#Mont-Black") format("svg"),
    url("#{$font-path}Mont-Black.ttf") format("truetype"),
    url("#{$font-path}Mont-Black.woff") format("woff"),
    url("#{$font-path}Mont-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-Heavy.eot");
  src: url("#{$font-path}Mont-Heavy.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-Heavy.svg#Mont-Heavy") format("svg"),
    url("#{$font-path}Mont-Heavy.ttf") format("truetype"),
    url("#{$font-path}Mont-Heavy.woff") format("woff"),
    url("#{$font-path}Mont-Heavy.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-Bold.eot");
  src: url("#{$font-path}Mont-Bold.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-Bold.svg#Mont-Bold") format("svg"),
    url("#{$font-path}Mont-Bold.ttf") format("truetype"),
    url("#{$font-path}Mont-Bold.woff") format("woff"),
    url("#{$font-path}Mont-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-SemiBold.eot");
  src: url("#{$font-path}Mont-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-SemiBold.svg#Mont-SemiBold") format("svg"),
    url("#{$font-path}Mont-SemiBold.ttf") format("truetype"),
    url("#{$font-path}Mont-SemiBold.woff") format("woff"),
    url("#{$font-path}Mont-SemiBold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-Regular.eot");
  src: url("#{$font-path}Mont-Regular.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-Regular.svg#Mont-Regular") format("svg"),
    url("#{$font-path}Mont-Regular.ttf") format("truetype"),
    url("#{$font-path}Mont-Regular.woff") format("woff"),
    url("#{$font-path}Mont-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-Light.eot");
  src: url("#{$font-path}Mont-Light.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-Light.svg#Mont-Light") format("svg"),
    url("#{$font-path}Mont-Light.ttf") format("truetype"),
    url("#{$font-path}Mont-Light.woff") format("woff"),
    url("#{$font-path}Mont-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-ExtraLight.eot");
  src: url("#{$font-path}Mont-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}Mont-ExtraLight.svg#Mont-ExtraLight") format("svg"),
    url("#{$font-path}Mont-ExtraLight.ttf") format("truetype"),
    url("#{$font-path}Mont-ExtraLight.woff") format("woff"),
    url("#{$font-path}Mont-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("#{$font-path}Mont-Thin.eot");
  src: url("#{$font-path}Mont-Thin.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Mont-Thin.svg#Mont-Thin") format("svg"),
    url("#{$font-path}Mont-Thin.ttf") format("truetype"),
    url("#{$font-path}Mont-Thin.woff") format("woff"),
    url("#{$font-path}Mont-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
$font-size-x-sm: 0.6rem;
$font-size-sm: 0.7rem;
$font-size-md: 0.8rem;
$font-size-lg: 13px;
$font-size-x-lg: 14px;

$font-size-xxx-lg: 1rem;
$font-size-xxxx-lg: 1.5rem;

$font-family-hind: "Hind", "Helvetica Neue", Arial, sans-serif;
$font-family-firasans: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-roboto: "Roboto", "Helvetica Neue", Arial, sans-serif;
$font-family-poppins: "Poppins", "Helvetica Neue", Arial, sans-serif;
$font-family-source: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
$font-family-montserrat: "Montserrat", "Fira Sans", "Helvetica Neue", Arial,
  sans-serif;
$font-family-lato: "Lato", "Helvetica Neue", Arial, sans-serif;
$font-family-barcode: "Libre Barcode 39 Extended Text", cursive;
$font-family-mont: "Mont", "Helvetica Neue", Arial, sans-serif;
$font-family-rubik: "Rubik", "Helvetica Neue", Arial, sans-serif;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bolder: bolder;

:root {
  --font-weight-normal: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --font-weight-light: 300;

  --font-size-x-lg: 20px;
  --font-size-lg: 17px;
  --font-size-md: 15px;
  --font-size-sm: 14px;
  --font-size-x-sm: 13px;

  --font-family-roboto: "Roboto", "Helvetica Neue", Arial, sans-serif;
}
